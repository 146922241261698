import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap, of } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from './user.service';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { UserGlobalRole } from 'app/models/user/user';
import { ProjectRoles } from 'app/models/project/project';
import { UserOrganizationRole } from 'app/models/organization/organization';

const navigationSuperAdmin: FuseNavigationItem = {
  icon: "",
  id: "admin-app",
  link: "",
  title: "Admin App",
  type: "group",
  children: [
    {
      icon: "mat_outline:dashboard",
      id: "admin-app.dashboard",
      link: "/admin-app/dashboard",
      title: "Dashboard",
      type: "basic",
    },
    {
      icon: "mat_outline:list_alt",
      id: "admin-app.organizations-list",
      link: "/admin-app/organizations-list",
      title: "Organizations List",
      type: "basic",
    }
  ]
};

const track: FuseNavigationItem = {
  id: "track",
  type: "group",
  title: "Track",
  children: [
    {
      icon: "mat_outline:history",
      id: "work-history",
      title: "Work History",
      type: "collapsable",
      exactMatch: true,
      children: [
        {
          icon: "mat_outline:timeline",
          id: "work-history",
          link: "/user/work-history/work-sessions",
          title: "Work Sessions",
          type: "basic",
          exactMatch: true,
        },
        {
          icon: "heroicons_outline:camera",
          id: "screenshots",
          link: "/user/work-history/screenshots",
          title: "Screenshots",
          type: "basic",
        },
      ]
    }
  ]
  // id: "track",
  // type: "group",
  // title: "Track",
  // children: [
  //   {
  //     icon: "mat_outline:history",
  //     id: "work-history",
  //     link: "/user/work-history/work-sessions",
  //     title: "Work History",
  //     type: "basic",
  //     exactMatch: true,
  //   },
  // ]
};

const dashboard: FuseNavigationItem = {
  icon: "mat_outline:dashboard",
  id: "admin.dashboard",
  link: "/organization/dashboard",
  title: "Dashboard",
  type: "basic",
}

const usersOnline: FuseNavigationItem = {
  icon: "heroicons_outline:status-online",
  id: "admin.users_online",
  link: "/organization/live",
  title: "Live",
  type: "basic",
}

const ownerAnalyze: FuseNavigationItem = {
  id: "analyze",
  type: "group",
  title: "Analyze",
  children: [
    {
      icon: "mat_outline:collections",
      id: "reports",
      title: "Reports",
      type: "collapsable",
      exactMatch: true,
      children: [
        {
          icon: "heroicons_solid:table",
          id: "time-reports",
          link: "/reports/simple/weekly",
          title: "Time reports",
          type: "basic",
        },
        {
          icon: "heroicons_outline:camera",
          id: "screenshots",
          link: "/screenshots",
          title: "Screenshots",
          type: "basic",
        },
      ]
    }
  ]
}

const analyze: FuseNavigationItem = {
  id: "analyze",
  type: "group",
  title: "Analyze",
  children: [
    {
      icon: "mat_outline:collections",
      id: "reports",
      title: "Reports",
      type: "collapsable",
      exactMatch: true,
      children: [
        {
          icon: "heroicons_solid:table",
          id: "time-reports",
          link: "/reports",
          title: "Time reports",
          type: "basic",
        },
        {
          icon: "heroicons_outline:camera",
          id: "screenshots",
          link: "/user/work-history/screenshots",
          title: "Screenshots",
          type: "basic",
        },
      ]
    }
  ]
}

const project: FuseNavigationItem = {
  icon: "heroicons_outline:briefcase",
  id: "projects",
  link: "organization/projects",
  title: "Projects",
  type: "basic",
  exactMatch: true
}
const people: FuseNavigationItem = {
  icon: "groups",
  id: "people",
  title: "People",
  type: "collapsable",
  exactMatch: true,
  children: [
    {
      icon: "mat_solid:people_outline",
      id: "members",
      link: "organization/members",
      title: "Members",
      type: "basic",
    },
    {
      icon: "mat_solid:people_outline",
      id: "teams",
      link: "organization/teams",
      title: "Teams",
      type: "basic",
    },
  ]
}

const manage: FuseNavigationItem = {
  id: "manage",
  type: "group",
  title: "Manage",
  children: [
    {
      icon: "playlist_add_check",
      id: "tasks",
      link: "/tasks",
      title: "Tasks",
      type: "basic",
    },
  ]
}

const navigationList: Navigation = {
  compact: [],
  futuristic: [],
  horizontal: [],
  default: [],
  footer: [
    {
      id: "admin ",
      type: "group",
      title: "Admin",
    },
    {
      icon: "mat_outline:settings",
      id: "settings",
      title: "Settings",
      type: "basic",
      link: "/settings",
      exactMatch: true,
    }
  ]
};

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get(role: UserOrganizationRole | UserGlobalRole | ProjectRoles): Observable<Navigation> {
    switch (role) {
      case UserGlobalRole.SuperAdmin:
        navigationList.default = [navigationSuperAdmin, track, ownerAnalyze, manage];
        break;
      case UserOrganizationRole.Owner:
      case UserOrganizationRole.Viewer:
        ownerAnalyze.children.unshift(dashboard, usersOnline);
        manage.children.push(project, people);
        navigationList.default = [track, ownerAnalyze, manage];
        break;
      case UserOrganizationRole.Member:
        navigationList.default = [track, analyze, manage];
        break;
    }
    return of(navigationList).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }
}