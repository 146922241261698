import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { ActivityUser } from 'app/models/activity-user';
import { DashboardTeam } from 'app/models/dashboards/dashboard-team';
import { convertToLocalDate } from 'app/utils/convert-to-local-date';
import { Observable, map } from 'rxjs';
import { OrganizationService } from './organization.service';
// import { UsersActivityData } from 'app/modules/organization/modules/reports/pages/activity/activity.component';
import { ObjectKind } from './objects-session';
import { Activity } from 'app/models/activity/activity';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  static timeZoneNumber = new Date().getTimezoneOffset() / 60 * (-1);
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly organizationService: OrganizationService) {
  }


  activity(params: HttpParams) {
    return this.httpClient.get<Activity>(`${this.appConfig.baseUrl}/reports/activity/team`, { params });
  }


  oranizationReport(params: HttpParams): Observable<{ downloadUri: string }> {
    return this.httpClient.post<any>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/reports`, null, { params });
  }

  getActivityUsers(params: HttpParams): Observable<any> {
    return this.httpClient.get<Array<any>>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/timesheet`, { params });
  }

  getActivityUser(params: HttpParams): Observable<any> {
    return this.httpClient.get<any>(`${this.appConfig.oldBaseUrl}/api/admin/windows/rawperiod`, { params }).pipe(
      map((data) => ({ userName: data.UserName, data: data.GroupListWindows || [] })),
      map(userActivity => ({
        userName: userActivity.userName,
        data: userActivity?.data?.map(userActivity => ({
          ...userActivity,
          CreatedDate: new Date(convertToLocalDate(userActivity.CreatedTime)).setHours(0, 0, 0, 0),
          CreatedTime: convertToLocalDate(userActivity.CreatedTime),
          ExitDate: convertToLocalDate(userActivity.ExitTime),
          ExitTime: convertToLocalDate(userActivity.ExitTime),
          userName: userActivity.userName
        }))
      })),
      map(item => ({
        ...item, data: item?.data.map(item => ({
          ...item,
          Total_Tracked: item.Duration,
        }))
      }))
    );
  }

  getDashboardTeamData(params: HttpParams): Observable<DashboardTeam> {
    return this.httpClient.get<DashboardTeam>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/chart/users`, { params });
  }

  getTimeSheets(/*groupBy: 'user' | 'category' | 'object' | 'application' | 'url' = 'user'*/params: HttpParams): Observable<Array<any>> {
    return this.httpClient.get<{ list: Array<any> }>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/timesheet`, { params }).pipe(
      map(list => list.list)
    );
  }


  downloadWeekly(params: HttpParams): Observable<{ downloadUri: string }> {
    params = params.append('typeReport', 'weekly');
    return this.downloadReport(params);
  }

  downloadMonthly(params: HttpParams): Observable<{ downloadUri: string }> {
    params = params.append('typeReport', 'monthly');
    return this.downloadReport(params);
  }
  downloadCustom(params: HttpParams): Observable<{ downloadUri: string }> {
    params = params.append('typeReport', 'custom');
    return this.downloadReport(params);
  }

  downloadReport(params: HttpParams): Observable<{ downloadUri: string }> {
    return this.httpClient.post<any>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/reports`, null, { params });
  }

}