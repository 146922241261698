import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, from, map, retry, shareReplay, tap } from 'rxjs';
import { SignalRService } from './signal-r.service';
import { ObjectKind } from './objects-session';
import { OrganizationService } from './organization.service';
import { DateTime } from 'luxon';
import { TimeTrackingWorkerService } from './time-tracking-worker.service';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {
  private _activeSessionStart: string = DateTime.now().set({ millisecond: 0 }).toISO();
  // private _worker: Worker;
  static DELTA = 60;
  private readonly _isPlay$ = new BehaviorSubject<boolean>(false);
  private _timeWorkedState = 0;
  sessionId: string | null = null;
  private readonly _duration$ = new BehaviorSubject<number>(0);
  private readonly _error$ = new BehaviorSubject<string | null>(null);
  private readonly _currentDate$ = new BehaviorSubject<DateTime>(DateTime.now().set({ millisecond: 0 }));
  private readonly signalService = inject(SignalRService);
  private readonly organizationService = inject(OrganizationService);
  private readonly timeTrackingWorkerService = inject(TimeTrackingWorkerService);

  constructor() {
    // this.initSW();
    this.timeTrackingWorkerService.worker.addEventListener('message', (message: MessageEvent) => {
      const { duration } = message.data;
      // console.log(duration, durationActive)
      this.tick(duration);
    });
  }

  get duration$() {
    return this._duration$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  readonly durationActive$ = this.currentDate$.pipe(
    map(currentDate => currentDate.diff(DateTime.fromISO(this._activeSessionStart), 'seconds').seconds),
    filter(duration => duration > 0)
  );

  get error$() {
    return this._error$.asObservable().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get currentDate$() {
    return this._currentDate$.pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get isPlay$() {
    return this._isPlay$.asObservable().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get isPlayValue() {
    return this._isPlay$.value;
  }
  get isConnected$() {
    return this.signalService.isConnected$.pipe(
      tap((isConnected) => {
        if (!!isConnected)
          this._error$.next(null);
      })
    );
  }
  get isReconnected$() {
    return this.signalService.isReconnected$;
  }
  get clientStarted$() {
    return this.signalService.on('ClientStarted').pipe(
      map(args => {
        const [orgId, sessionId, objectId, objectKind, startedAt, connectId] = args;
        return { orgId, sessionId, objectId, objectKind, startedAt, connectId };
      }),
    );
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, objectId: string, objectKind: ObjectKind, startedAt: string, connectId: string }>((observer) => {
    //     this.signalService.hubConnection.on('ClientStarted', (orgId, sessionId, objectId, objectKind, startedAt, connectId) => {
    //       observer.next({ orgId, sessionId, objectId, objectKind, startedAt, connectId });
    //     });
    //   }))),
    //   retry({ delay: () => this.isReconnected$ }),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get clientStopped$() {
    return this.signalService.on('ClientStopped').pipe(
      map(args => {
        const [orgId, sessionId, stoppedAt, timeWorked, connectId] = args;
        return { orgId, sessionId, stoppedAt, timeWorked, connectId };
      })
    );
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, stoppedAt: string, timeWorked: number, connectId: string, }>((observer) => {
    //     this.signalService.hubConnection.on('ClientStopped', (orgId, sessionId, stoppedAt, timeWorked, connectId) => {
    //       observer.next({ orgId, sessionId, stoppedAt, timeWorked, connectId });
    //     });
    //   }))),
    //   retry({ delay: () => this.isReconnected$ }),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get clientPaused$() {
    return this.signalService.on('ClientPaused');
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('ClientPaused', () => {
    //       observer.next();
    //     });
    //   }))),
    //   retry({ delay: () => this.isReconnected$ }),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get clientTimeWorkedUpdated$() {
    return this.signalService.on('ClientTimeWorkedUpdated').pipe(
      map(args => {
        const [orgId, timeWorked, commitedAtt] = args;
        return { orgId, timeWorked, commitedAtt };
      }),
    );
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<{ orgId: string, timeWorked: number, commitedAtt: string }>((observer) => {
    //     this.signalService.hubConnection.on('ClientTimeWorkedUpdated', (orgId, timeWorked, commitedAtt) => {
    //       observer.next({ orgId, timeWorked, commitedAtt });
    //     });
    //   }))),
    //   retry({ delay: () => this.isReconnected$ }),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get clientSessionObjectChanged$() {
    return this.signalService.on('ClientSessionObjectChanged').pipe(
      map(args => {
        const [orgId, sessionId, objectId, objectKind, connectId] = args;
        return { orgId, sessionId, objectId, objectKind, connectId };
      })
    );
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, objectId: string, objectKind: ObjectKind, connectId: string }>((observer) => {
    //     this.signalService.hubConnection.on('ClientSessionObjectChanged', (orgId, sessionId, objectId, objectKind, connectId) => {
    //       observer.next({ orgId, sessionId, objectId, objectKind, connectId });
    //     });
    //   }))),
    //   retry({ delay: () => this.isReconnected$ }),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }

  get clientSessionResumed$() {
    return this.signalService.on('ClientResumed').pipe(
      map(args => {
        const [orgId] = args;
        return { orgId };
      })
    );
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<{ orgId: string }>((observer) => {
    //     this.signalService.hubConnection.on('ClientResumed', (orgId) => {
    //       observer.next({ orgId });
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }

  get sessionCreated$() {
    return this.signalService.on('SessionCreated');
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('SessionCreated', () => {
    //       observer.next();
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get sessionSubjectChanged$() {
    return this.signalService.on('SessionSubjectChanged');
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('SessionSubjectChanged', () => {
    //       observer.next();
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get sessionResized$() {
    return this.signalService.on('SessionResized');
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('SessionResized', () => {
    //       observer.next();
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get sessionSwitched$() {
    return this.signalService.on('SessionSwitched');
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('SessionSwitched', () => {
    //       observer.next();
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }
  get sessionDeleted$() {
    return this.signalService.on('SessionDeleted');
    // return this.isConnected$.pipe(
    //   switchMap(() => defer(() => new Observable<void>((observer) => {
    //     this.signalService.hubConnection.on('SessionDeleted', () => {
    //       observer.next();
    //     });
    //   }))),
    //   shareReplay({ bufferSize: 1, refCount: true })
    // );
  }

  private startSession(objectId: string, objectKind: ObjectKind, sessionId: string, date: string): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.invoke('StartSession', orgId, objectId, objectKind, sessionId, date).then(() => console.log('TTS next!')).catch(() => console.error('TTS Error!')));
  }

  private endSession(sessionId: string, date: string): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.invoke('EndSession', orgId, sessionId, date));
  }

  commitSession(sessionId: string, date: string): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.invoke('CommitTime', orgId, sessionId, date) ?? Promise.reject(new Error('not connection'));
  }

  commitSessionV2(sessionId: string, committedAt: string, pauseToken: string): Promise<any> {
    const organizationId = this.organizationService.organizationSelectedId;
    const data = {
      organizationId,
      sessionId,
      committedAt,
      pauseToken
    };
    return this.signalService.invokeV2('CommitTimeV2', data) ?? Promise.reject(new Error('not connection'));
  }

  сhangeObject(objectId: string, objectKind: ObjectKind): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.invoke('ChangeObject', [orgId, this.sessionId, objectId, objectKind]));
  }

  init(timeWorked: number, date: string) {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked);
  }

  play(objectId: string, objectKind: ObjectKind, date: string): Observable<any> {
    if (!this.isPlayValue) {
      this._activeSessionStart = DateTime.fromISO(date).set({ millisecond: 0 }).toISO();
      this.sendToWorker('stop', date);
      this.sessionId = crypto.randomUUID()
      this._isPlay$.next(true);
      this.sendToWorker('start', date, date);
      return this.startSession(objectId || this.organizationService.organizationSelectedId, objectKind || ObjectKind.Project, this.sessionId, date).pipe(
        tap({
          next: (data) => {
            console.log('TTS next!')
            if (data.statusCode === 409) {
              this._isPlay$.next(false);
              this.sendToWorker('stop', date);
              this._duration$.next(this._timeWorkedState);
              this._error$.next('409: conflict');
            }
          },
          error: (err) => {
            console.error('TTS Error!')
            this._isPlay$.next(false);
            this.sendToWorker('stop', date);
            this._duration$.next(this._timeWorkedState);
            this._error$.next(err);
          }
        }),
        retry({ count: 2, delay: 2000 })
      );
    } else return this.сhangeObject(objectId, objectKind);
  }

  stop(date: string): Observable<any> {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    // this.endSession(this.sessionId, date).then(
    //   () => {
    //     this.sessionId = null;
    //     this._timeWorkedState = this._duration$.value;
    //   }).catch((err) => this._error$.next(err));
    return this.endSession(this.sessionId, date).pipe(
      tap({
        next: () => {
          this.sessionId = null;
          this._timeWorkedState = this._duration$.value;
        },
        error: (err) => this._error$.next(err)
      }),
      retry({ count: 2, delay: 2000 })
    );
  }

  private commit() {
    const commitDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
    this.commitSession(this.sessionId, commitDate).catch((err) => {
      // this._isPlay$.next(false);
      // this.sendToWorker('stop', commitDate);
    });
  }

  private sendToWorker(event: 'start' | 'stop', committedAt: string, starttedAt?: string) {
    this.timeTrackingWorkerService.worker.postMessage({ event, committedAt, starttedAt });
  }

  run(sessionId: string, committedAt: string, starttedAt?: string) {
    this._activeSessionStart = DateTime.fromISO(starttedAt || committedAt).set({ millisecond: 0 }).toISO()
    this.sendToWorker('stop', committedAt);
    this.sessionId = sessionId;
    this._isPlay$.next(true);
    const timeDifference = Math.floor(DateTime.now().set({ millisecond: 0 }).toUTC().diff(DateTime.fromISO(committedAt), 'seconds').seconds);
    this._duration$.next(timeDifference + this._timeWorkedState);
    this.sendToWorker('start', committedAt, starttedAt);
  }

  updateDuration(timeWorked: number, committedAt: string) {
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked);
    this.sendToWorker(null, committedAt);
  }

  private tick(duration: number) {
    if (duration > 0) {
      this._currentDate$.next(DateTime.now().set({ millisecond: 0 }));
      // this._durationLocal$.next(durationActive);
      const duarationAll = this._timeWorkedState + duration;
      this._duration$.next(duarationAll);
      // console.log(duration, duration % TimeTrackingService.DELTA === 0)
      if (duration % TimeTrackingService.DELTA === 0) {
        this.commit();
      }
    }
  }
}