import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormsModule, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { MaterialModule } from 'app/core/material/material.module';

export class Duration {
  constructor(public hour: string, public minute: string, public second: string) { }
}

@Component({
  selector: 'tm-duration-form-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, FormsModule],
  templateUrl: './duration-form-field.component.html',
  styleUrls: ['./duration-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: DurationFormFieldComponent }],
  host: {
    '[class.duration-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class DurationFormFieldComponent implements ControlValueAccessor, MatFormFieldControl<Duration>, OnDestroy {
  static nextId = 0;
  @ViewChild('hour') hourInput: HTMLInputElement;
  @ViewChild('minute') miniteInput: HTMLInputElement;
  @ViewChild('second') secondInput: HTMLInputElement;

  readonly parts = this._formBuilder.group({
    hour: ['', [Validators.required, ,Validators.pattern("[0-9][0-9]"), Validators.minLength(2), Validators.maxLength(2), Validators.max(23), Validators.min(0)]],
    minute: ['', [Validators.required, ,Validators.pattern("[0-9][0-9]"), Validators.minLength(2), Validators.maxLength(2), Validators.max(59), Validators.min(0)]],
    second: ['', [Validators.required, ,Validators.pattern("[0-9][0-9]"), Validators.minLength(2), Validators.maxLength(2), Validators.max(59), Validators.min(0)]],
  });
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'duration-input';
  id = `duration-input-${DurationFormFieldComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  get empty() {
    const {
      value: { hour, minute, second },
    } = this.parts;

    return !hour && !minute && !second;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Duration | null {
    if (this.parts.valid) {
      const {
        value: { hour, minute, second },
      } = this.parts;
      return new Duration(hour!, minute!, second!);
    }
    return null;
  }
  set value(duration: Duration | null) {
    const { hour, minute, second } = duration || new Duration('', '', '');
    this.parts.setValue({ hour, minute, second });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
    this.setDefaulValue();
  }

  private setDefaulValue() {
    // const minute = +this.parts.get('minute').value;
    // if (!minute) {
    //   this.parts.patchValue({ minute: '00' });
    // }
    const second = +this.parts.get('second').value;
    if (!second) {
      this.parts.patchValue({ second: '00' });
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.duration-input-container',
    )!;
    controlElement.setAttribute('aria-seconds', ids.join(' '));
  }

  onContainerClick() {
    // if (this.parts.controls.seconds.valid) {
    //   this._focusMonitor.focusVia(this.secondsInput, 'program');
    // } else if (this.parts.controls.minites.valid) {
    //   this._focusMonitor.focusVia(this.secondsInput, 'program');
    // } else if (this.parts.controls.hours.valid) {
    //   this._focusMonitor.focusVia(this.minitesInput, 'program');
    // } else {
    //   this._focusMonitor.focusVia(this.hoursInput, 'program');
    // }
  }

  writeValue(tel: Duration | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}