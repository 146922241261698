<div class="flex flex-col gap-4" [formGroup]="sessionForm">
  <div class="flex justify-between items-center">
    <div class="text-2xl">
      <span *ngIf="data.type === 'add'">Add Time</span>
      <span *ngIf="data.type === 'edit'">Edit Time</span>
    </div>
    <button mat-icon-button class="ml-auto" matDialogClose>
      <mat-icon svgIcon="mat_solid:close"></mat-icon>
    </button>
  </div>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Projects">
      <div class="mt-4 px-0.5">
        <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
          <mat-label>Project</mat-label>
          <!-- <mat-select formControlName="object" [compareWith]="compare">
              <mat-option [value]="{id: object.id, objectKind: object.objectKind}" *ngFor="let object of projects$ | async">{{object.title}}</mat-option>
          </mat-select> -->
          <mat-select placeholder="Select Project" formControlName="object" [compareWith]="compareFn">
            <mat-option>
              <ngx-mat-select-search [formControl]="projectFilterCtrl" placeholderLabel="Find Project..."
                noEntriesFoundLabel="'no matching project found'"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let object of projectsListFilter$ | async"
              [value]="{id: object.id, objectKind: object.objectKind}">{{object.title}}</mat-option>
          </mat-select>
          <mat-icon matSuffix svgIcon="mat_outline:folder"></mat-icon>
          <mat-error>Select Project!</mat-error>
        </mat-form-field>
      </div>
    </mat-tab>
    <mat-tab label="Tasks">
      <div class="mt-4 px-0.5">
        <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
          <mat-label>Task</mat-label>
          <!-- <mat-select formControlName="object" [compareWith]="compare">
              <mat-option [value]="{id: object.id, objectKind: object.objectKind}" *ngFor="let object of tickets$ | async">{{object.title}}</mat-option>
          </mat-select> -->
          <mat-select placeholder="Select Task" formControlName="object" [compareWith]="compareFn">
            <mat-option>
              <ngx-mat-select-search [formControl]="ticketFilterCtrl" placeholderLabel="Find Task..."
                noEntriesFoundLabel="'no matching task found'"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let object of ticketsListFilter$ | async"
              [value]="{id: object.id, objectKind: object.objectKind}">{{object.title}}</mat-option>
          </mat-select>
          <mat-icon matSuffix svgIcon="mat_outline:task"></mat-icon>
          <mat-error>Select Task!</mat-error>
        </mat-form-field>
      </div>
    </mat-tab>
    <mat-tab label="Recent">
      <div class="mt-4 px-0.5">
        <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
          <mat-label>Project or Task</mat-label>
          <mat-select formControlName="object" [compareWith]="compareFn">
            <mat-optgroup [label]="objects.key" *ngFor="let objects of objects$ | async | keyvalue">
              <mat-option [value]="{id: object.id, objectKind: object.objectKind}"
                *ngFor="let object of objects.value">{{object.title}}</mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error>Select Project or Task!</mat-error>
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="flex flex-col md:flex-row items-start justify-between gap-2 px-0.5">
    <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker" readonly placeholder="Choose a date" formControlName="date" (click)="picker.open()" [max]="maxTime" (dateChange)="dateChange($event)">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      <mat-error>Start Time error!</mat-error>
    </mat-form-field>
    <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>Start Time</mat-label>
      <input type="text" matInput [matTimepicker]="timepickerStart" formControlName="start" readonly (click)="timepickerStart.open()"/>
      <mat-timepicker-toggle matSuffix [for]="timepickerStart"></mat-timepicker-toggle>
      <mat-timepicker #timepickerStart></mat-timepicker>
      <!-- <mat-hint>hh:mm</mat-hint> -->
    </mat-form-field>
    <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic" >
      <mat-label>End Time</mat-label>
      <input type="text" matInput [matTimepicker]="timepickerEnd" formControlName="end" readonly (click)="timepickerEnd.open()"/>
      <mat-timepicker-toggle matSuffix [for]="timepickerEnd"></mat-timepicker-toggle>
      <mat-timepicker #timepickerEnd></mat-timepicker>
      <mat-error *ngIf="this.sessionForm.get('end').hasError('nextDay')">Next Day</mat-error>
      <mat-error *ngIf="this.sessionForm.get('end').hasError('noValid')">End Time should be greater than the Start Time!</mat-error>
      <!-- <mat-hint>hh:mm</mat-hint> -->
    </mat-form-field>
    <!-- <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>Start Time</mat-label>
      <input matInput [ngxMatDatetimePicker]="pickerStart" placeholder="Choose a date" formControlName="start" readonly
        (click)="pickerStart.open()" [max]="maxTime">
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #pickerStart [showSpinners]="true" [showSeconds]="false" (closed)="closedPickerStart()">
      </ngx-mat-datetime-picker>
      <mat-error>Start Time error!</mat-error>
    </mat-form-field>
    <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
      <mat-label>End Time</mat-label>
      <input matInput [ngxMatDatetimePicker]="pickerEnd" placeholder="Choose a date" formControlName="end" readonly
        (click)="pickerEnd.open()" [max]="maxTime">
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #pickerEnd [showSpinners]="true" [showSeconds]="false" (closed)="closedPickerEnd()">
      </ngx-mat-datetime-picker>
      <mat-error>End Time error!</mat-error>
    </mat-form-field> -->
  </div>
  <div>
    <mat-form-field class="min-w-36 fuse-mat-dense" subscriptSizing="dynamic" >
      <mat-label>Duration</mat-label>
      <!-- <input type="text" matInput formControlName="duration"/> -->
      <tm-duration-form-field formControlName="duration" readonly></tm-duration-form-field>
      <mat-hint>hh:mm:ss</mat-hint>
    </mat-form-field>
  </div>
  <div class="flex flex-col" mat-dialog-actions>
    <div class="flex gap-2 ml-auto">
      <button mat-flat-button matDialogClose>Cancel</button>
      <button mat-raised-button color="primary" (click)="submit()"
        [disabled]="(submitting$ | async) || sessionFormInvalid">Submit</button>
    </div>
  </div>
</div>