import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, debounceTime, map, merge, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { InviteService } from '../../invite.service';
import { ProjectService } from 'app/services/project.service';
import { FuseCardModule } from '@fuse/components/card';
import { MaterialModule } from 'app/core/material/material.module';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserOrganizationRole } from 'app/models/organization/organization';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Team } from 'app/models/organization/team/team';
import { TeamsService } from 'app/modules/organization/modules/people/services/teams.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { AutocompleteSelectProjectsComponent } from 'app/shared/components/autocomplete-select-projects/autocomplete-select-projects.component';
import { lengthValidator } from 'app/utils/validators/length';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Project } from 'app/models/project/project';

@Component({
  selector: 'tm-invite-form',
  templateUrl: './invite-form.component.html',
  styleUrls: ['./invite-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FuseCardModule, MaterialModule, ReactiveFormsModule, CommonModule, AutocompleteSelectProjectsComponent]
})
export class InviteFormComponent implements OnInit, OnDestroy {
  static EMAIL = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('teamSearchInput') teamSearchInput: ElementRef<HTMLInputElement>;
  readonly teamsSearchFC = new FormControl<string>('');
  private readonly destroy$ = new Subject<void>();
  @ViewChild('emailInput', { static: true }) emailInput: ElementRef<HTMLInputElement>;

  readonly submitting$ = new BehaviorSubject<boolean>(false);
  readonly submitted$ = new BehaviorSubject<boolean>(false);
  readonly inviteForm = new FormGroup({
    emails: new FormControl<Array<string>>([], lengthValidator()),
    role: new FormControl<string>({ value: UserOrganizationRole.Member, disabled: true }),
    projects: new FormControl<Array<Project>>([]),
    teams: new FormControl<Array<Team>>([]),
  });

  get emailsFC() {
    return this.inviteForm.get('emails');
  }
  get projectsFC(): FormControl<Array<Project>> {
    return this.inviteForm.get('projects') as unknown as FormControl<Array<Project>>;
  }
  get teamsFC() {
    return this.inviteForm.get('teams');
  }
  get roleFC() {
    return this.inviteForm.get('role');
  }

  constructor(
    @Optional() private readonly dialogRef: MatDialogRef<InviteFormComponent>,
    private readonly fb: FormBuilder,
    private inviteService: InviteService,
    private readonly projectService: ProjectService,
    private readonly snackBar: MatSnackBar,
    private readonly teamsService: TeamsService) { }

  // readonly projects$ = merge(this.projectsFC.valueChanges.pipe(startWith([])), this.projectsSearchFC.valueChanges.pipe(startWith(''))).pipe(
  //   debounceTime(150),
  //   switchMap(() => {
  //     const selectedProjects = this.projectsFC.value;
  //     const projectsSearch = this.projectsSearchFC.value || '';
  //     return this.projectService.projects$.pipe(
  //       map((projects) => projects.filter(project => project.status === ProjectStatus.Normal)),
  //       map((projects) => projects.filter(project => (!selectedProjects.map(p => p.id).includes(project.id)) && project.name.toLowerCase().includes(projectsSearch?.toLowerCase()?.trim())))
  //     )
  //   })
  // );

  readonly teams$ = merge(this.teamsFC.valueChanges.pipe(startWith([])), this.teamsSearchFC.valueChanges.pipe(startWith(''))).pipe(
    debounceTime(150),
    switchMap(() => {
      const selectedTeams = this.teamsFC.value;
      const teamsSearch = this.teamsSearchFC.value || '';
      return this.teamsService.get().pipe(
        map((teams) => teams.filter(team => (!selectedTeams.map(p => p.id).includes(team.id)) && team.name.toLowerCase().includes(teamsSearch?.toLowerCase()?.trim())))
      )
    })
  );

  // projectSelected(e: MatAutocompleteSelectedEvent) {
  //   const project = e.option.value;
  //   if (project) {
  //     this.projectsFC.setValue([...this.projectsFC.value, project]);
  //     this.projectSearchInput.nativeElement.value = '';
  //     this.projectsSearchFC.setValue('', { emitEvent: false });
  //   }
  // }
  // removeProject(id: string) {
  //   const leads = (this.projectsFC.value as Array<Project>).filter(project => project.id !== id);
  //   this.projectsFC.setValue(leads);
  // }

  teamSelected(e: MatAutocompleteSelectedEvent) {
    const team = e.option.value;
    if (team) {
      this.teamsFC.setValue([...this.teamsFC.value, team]);
      this.teamSearchInput.nativeElement.value = '';
      this.teamsSearchFC.setValue('', { emitEvent: false });
    }
  }
  removeTeam(id: string) {
    const teams = (this.teamsFC.value as Array<Team>).filter(team => team.id !== id);
    this.teamsFC.setValue(teams);
  }

  addEmail(value: string): void {
    const emails = (value || '').trim().split(',');
    const invalidEmails = [];
    emails.forEach(email => {
      if (email) {
        if (InviteFormComponent.EMAIL.test(email)) {
          this.emailsFC.setValue([...this.emailsFC.value, email]);
        } else {
          invalidEmails.push(email)
          this.emailsFC.markAsTouched();
          this.emailsFC.setErrors({ isNotValid: true });
          this.emailInput.nativeElement.value = '';
        }
      }
    });
    this.emailInput.nativeElement.value = invalidEmails.join(',');
    // if (email) {
    //   if (InviteFormComponent.EMAIL.test(email)) {
    //     this.emailsFC.setValue([...this.emailsFC.value, email]);
    //   } else {
    //     this.emailsFC.markAsTouched();
    //     this.emailsFC.setErrors({ isNotValid: true });
    //     return
    //   }
    // }
    // event.chipInput!.clear();     this.emailInput.nativeElement.value = '';
  }

  removeEmail(email: string) {
    const emails = this.emailsFC.value.filter(e => e !== email);
    this.emailsFC.setValue(emails);
    // this.emailsFC.updateValueAndValidity();
  }

  emailAdd() {

  }

  submit(): void {
    this.submitting$.next(true);
    const data = {
      list: this.emailsFC.value.flatMap(email => this.projectsFC.value?.map(project => ({ email, projectId: project.id, projectRole: this.roleFC.value })) || [])
    };
    console.log(data);
    this.inviteService.sendInviteToTeam(data).subscribe({
      next: () => {
        this.submitting$.next(false);
        this.submitted$.next(true);
        this.dialogRef?.close(true);
        const msg = (data.list.length > 1) ? 'Users have been invited!' : 'A User has been invited!'
        this.snackBar.open(msg, 'Ok', { duration: 5000 });
      },
      error: () => {
        this.submitting$.next(false);
        this.submitted$.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.projectsFC.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(projects => {
      if (projects.length > 0) {
        this.roleFC.enable();
      } else this.roleFC.disable();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete()
  }
}
