import { ChangeDetectionStrategy, Component, ViewContainerRef, ChangeDetectorRef, ViewChild, TemplateRef, EventEmitter, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatFormField } from "@angular/material/form-field";
import { DateRange } from '@angular/material/datepicker';
import { Subject, takeUntil, startWith, debounceTime, map, skip, tap, EMPTY } from "rxjs";
import { DateTime } from "luxon";
import { ActivatedRoute, Router } from '@angular/router';

interface Preset {
  id: number,
  name: string,
  start: DateTime,
  end: DateTime
}

@Component({
  selector: 'tm-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
  maxDate = DateTime.now();
  static readonly MIDNIGHT = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  readonly rangeFG = this.fb.group({
    start: [DateTime.now().set(DateRangePickerComponent.MIDNIGHT).minus({ days: 7 }), Validators.required],
    end: [DateTime.now(), Validators.required]
  });
  selected: DateRange<DateTime> = new DateRange(this.rangeFG.get('start').value, this.rangeFG.get('end').value)


  private readonly destroy$ = new Subject<void>();
  @ViewChild('calendarPanel') private calendarsPanel: TemplateRef<any>;
  @ViewChild('inputOrigin') private inputOrigin: MatFormField;
  presetSelected = 1;
  @Output() private readonly changeRange = new EventEmitter<{ start: string, end?: string }>();
  presets: Preset[];
  private overlayRef: OverlayRef;

  @Input() set range(range: { start: string, end: string }) {
    const [start, end] = [DateTime.fromISO(range.start).set(DateRangePickerComponent.MIDNIGHT), DateTime.fromISO(range.end)];
    if (start.isValid && end.isValid) {
      this.rangeFG.patchValue({
        start,
        end: (end > this.maxDate) ? this.maxDate : end,
      });
    }
    this.presetSelected = null;
    this.selected = new DateRange(this.rangeFG.get('start').value, this.rangeFG.get('end').value);
  }
  constructor(
    private readonly chr: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly overlay: Overlay,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) { }

  private populatePresets(): void {
    const currentDate = DateTime.now();
    const startCurrentDate = DateTime.fromMillis(currentDate.toMillis()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const previusMonth = DateTime.fromMillis(currentDate.toMillis()).minus({ month: 1 });
    this.presets = [
      {
        id: 0,
        name: 'This week',
        start: DateTime.fromMillis(startCurrentDate.toMillis()).minus({ days: currentDate.weekday - 1 }),
        end: DateTime.fromMillis(currentDate.toMillis())
      },
      {
        id: 1,
        name: 'Last 7 days',
        start: DateTime.fromMillis(startCurrentDate.toMillis()).minus({ days: 6 }),
        end: DateTime.fromMillis(currentDate.toMillis())
      },
      {
        id: 2,
        name: 'Last 14 days',
        start: DateTime.fromMillis(startCurrentDate.toMillis()).minus({ days: 13 }),
        end: DateTime.fromMillis(currentDate.toMillis())
      },
      {
        id: 3,
        name: 'Last 30 days',
        start: DateTime.fromMillis(startCurrentDate.toMillis()).minus({ days: 29 }),
        end: DateTime.fromMillis(currentDate.toMillis())
      },
      {
        id: 4,
        name: 'This Month',
        start: DateTime.local(startCurrentDate.year, startCurrentDate.month, 1),
        end: DateTime.fromMillis(currentDate.toMillis())
      },
      {
        id: 5,
        name: 'Previous Month',
        start: DateTime.local(previusMonth.year, previusMonth.month, 1),
        end: DateTime.local(previusMonth.year, previusMonth.month, 1).plus({ months: 1 }).minus({ days: 1 })
      },
    ];
  }

  selectedRange(range: DateTime) {
    if (!this.selected?.start || this.selected?.end) {
      this.selected = new DateRange(range, null);
    } else {
      const start = this.selected.start;
      const end = range;
      if (end < start) {
        this.selected = new DateRange(end, start);
      } else {
        this.selected = new DateRange(start, end);
      }
    }
    this.rangeFG.patchValue({
      start: this.selected.start,
      end: this.selected.end
    });
    this.presetSelected = null;
    this.chr.markForCheck();
  }

  selectPreset({ value }): void {
    this.presetSelected = value.id;
    const start = value.start;
    const end = value.end;
    this.rangeFG.patchValue({
      start,
      end
    });
    this.selected = new DateRange(start, end);
    this.closePanel();
  }

  openPanel(): void {
    this.populatePresets();
    // Return if the shortcuts panel or its origin is not defined
    if (!this.calendarsPanel || !this.inputOrigin) {
      return;
    }
    // Create the overlay if it doesn't exist
    if (!this.overlayRef) {
      this._createOverlay();
    }
    // Attach the portal to the overlay
    this.overlayRef.attach(new TemplatePortal(this.calendarsPanel, this.viewContainerRef));
  }

  closePanel(): void {
    this.overlayRef.detach();
  }

  private _createOverlay(): void {
    // Create the overlay
    this.overlayRef = this.overlay.create({
      //minHeight: 350,
      // panelClass: ['translate-y-1/2', '-top-1/2'],
      minWidth: 300,
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.inputOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          },
          {
            originX: 'center',
            originY: 'center',
            overlayX: 'center',
            overlayY: 'center'
          }
        ])
    });

    // Detach the overlay from the portal on backdrop click
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }

  ngOnInit(): void {
    this.rangeFG.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      takeUntil(this.destroy$),
      map(() => {
        if (this.rangeFG.valid) {
          const { start, end } = this.rangeFG.value;
          const range = {
            start: new Date(start.toMillis()).toJSON(),
          };
          if (end.set(DateRangePickerComponent.MIDNIGHT).toMillis() !== DateTime.now().set(DateRangePickerComponent.MIDNIGHT).toMillis()) {
            range['end'] = new Date(end.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toMillis()).toJSON();
          }
          this.changeRange.emit(range);
          return range;
        }
      }),
      skip(1),
      tap((range) => {
        if (range) {
          this.router.navigate(['./'], { queryParams: { ...range }, relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}