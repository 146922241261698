import { ChangeDetectionStrategy, Component, EventEmitter, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { INDUSTRYLIST } from 'app/models/industry/industry-list';
import { OrganizationService } from 'app/services/organization.service';
import { BehaviorSubject, switchMap } from 'rxjs';
import { MaterialModule } from 'app/core/material/material.module';
import { CommonModule } from '@angular/common';
import { CountryCodeSelectComponent } from 'app/shared/components/country-code-select/country-code-select.component';
import { TimeZoneSelectComponent } from 'app/shared/components/time-zone-select/time-zone-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum CompletedSteps {
  'organizationCreate' = 'organization: create',
  'industryCreate' = 'industry: create',
  'roleCreate' = 'role: create',
};

@Component({
  selector: 'tm-organization-add-form',
  templateUrl: './organization-add-form.component.html',
  styleUrls: ['./organization-add-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    CountryCodeSelectComponent,
    TimeZoneSelectComponent
  ]
})
export class OrganizationAddFormComponent {
  private createdOrganizationId = '';
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @Output() readonly done = new EventEmitter<string>();
  readonly idustryList = INDUSTRYLIST;
  readonly submitting$ = new BehaviorSubject<boolean>(false);


  readonly mainForm = this.fb.group({
    organization: this.fb.group({
      name: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      description: null,
      countryCode: [null, [Validators.required]],
      timeZoneId: [Intl.DateTimeFormat().resolvedOptions().timeZone, [Validators.required]],
    }),
    industry: this.fb.group({
      name: [null, [Validators.required]],
    })
  });

  get organizationFG(): FormGroup {
    return this.mainForm.get('organization') as FormGroup;
  }
  get industryFG(): FormGroup {
    return this.mainForm.get('industry') as FormGroup;
  }

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly organizationService: OrganizationService,
    private readonly fb: FormBuilder) { }

  organizationCreate(): void {
    this.submitting$.next(true);
    const data = {
      name: this.organizationFG.get('name').value,
      description: this.organizationFG.get('description').value || null,
      timeZoneId: this.organizationFG.get('timeZoneId').value || null,
      countryCode: this.organizationFG.get('countryCode').value || null,
      industry: this.industryFG.get('name').value
    };
    this.organizationService.add(data).pipe(
      switchMap(organization => {
        this.createdOrganizationId = organization.id;
        const data = {
          skipped: false,
          completed: false,
          completedSteps: [CompletedSteps.organizationCreate, CompletedSteps.industryCreate],
        }
        return this.organizationService.completeOnboard(this.createdOrganizationId, data);
      })
    ).subscribe({
      next: () => {
        this.done.emit(this.createdOrganizationId);
      },
      error: (err) => {
        this.submitting$.next(false);
        this.snackBar.open(err, 'Ok');
      }
    });
  }
}