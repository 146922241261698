import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivityUser } from 'app/models/activity-user';
import { ReportsService } from 'app/services/report.service';
import { ReplaySubject, shareReplay, map, switchMap, Subject, combineLatest, startWith, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

type FieldGroup = Extract<keyof ActivityUser, string>;

interface ActivityUserReport {
  date: number | string | Date,
  totalTracked: number,
  autoTracked: number,
  activity: number,
  manualTracked: number,
}

interface Range {
  start?: number | Date,
  end?: number | Date
}

interface TotalTime {
  all: number,
  manual: number,
  auto: number
}

const ratioDictionaryMap = {
  CreatedDate: 'CreatedDate',
  PrjId: 'PrjName',
  TaskId: 'TaskName',
  UserId: 'UserName'
};

const ratioDictionaryMap2 = {
  CreatedDate: 'Date',
  PrjId: 'Project',
  TaskId: 'Task',
  UserId: 'UserName'
};

@Component({
  selector: 'tm-time-and-activity-user-report',
  templateUrl: './time-and-activity-user-report.component.html',
  styleUrls: ['./time-and-activity-user-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeAndActivityUserReportComponent {
  readonly grouping$ = new Subject<FieldGroup>();
  selectedGroupField = '';
  readonly columnsToDisplay = ['date', 'totalTracked', 'activity'];
  readonly selectedRange$ = new ReplaySubject<Range>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  readonly totalTime$ = new ReplaySubject<TotalTime>();
  dataReport: Array<ActivityUser>;

  static groupBy(data: Array<ActivityUser>, fieldGroup: FieldGroup = 'CreatedDate'): ActivityUserReport[] {
    const dataGrouped = new Map<number | string, Array<ActivityUser>>();
    data.forEach(user => {
      const valueGroup = user[ratioDictionaryMap[fieldGroup]];
      dataGrouped.has(valueGroup) ? dataGrouped.get(valueGroup).push(user) : dataGrouped.set(valueGroup, [user]);
    });
    const serializeData = Array.from(dataGrouped, ([key, value]) => ({ date: key, children: value }));
    const result = serializeData.map(data => {
      const userStatistic = data.children.reduce((acc, curr) => {
        acc.totalTracked += curr.Total_Tracked;
        acc.manualTracked += curr.ManualTime ? curr.Total_Tracked : 0;
        acc.autoTracked += !curr.ManualTime ? curr.Total_Tracked : 0;
        acc.activity += curr.Activity;
        return acc;
      }, { totalTracked: 0, manualTracked: 0, autoTracked: 0, activity: 0 });
      return ({ date: data.date, ...userStatistic });
    });
    return result;
  }

  constructor(private readonly reportsService: ReportsService) { }

  private setParams(range: Range): HttpParams {
    let params = new HttpParams();
    const start = new Date(new Date(range.start).setHours(0, 0, 0, 0)).toJSON().replace('Z', '');
    const end = new Date(new Date(range.end).setHours(23, 59, 59, 999)).toJSON().replace('Z', '');
    params = params.append('BeginDate', start);
    params = params.append('EndDate', end);
    return params;
  }

  readonly report$ = combineLatest([
    this.grouping$.pipe(
      startWith('CreatedDate' as FieldGroup),
      tap((v) => this.selectedGroupField = ratioDictionaryMap2[v])
    ),
    this.selectedRange$.pipe(
      switchMap((range) => this.reportsService.getActivityUser(this.setParams(range))),
      tap(data => this.dataReport = data?.data),
      map(data => data?.data),
    )]).pipe(
      map(([fieldGroup, data]) => TimeAndActivityUserReportComponent.groupBy(data, fieldGroup)),
      map(data => {
        const totalTime = data.reduce((acc, item) => {
          acc.all += item.totalTracked;
          acc.auto += item.autoTracked;
          acc.manual += item.manualTracked;
          return acc;
        }, { all: 0, manual: 0, auto: 0 });
        this.totalTime$.next(totalTime);
        const dataSource = new MatTableDataSource(data);
        dataSource.paginator = this.paginator;
        dataSource.sort = this.sort;
        return dataSource;
      }),
      shareReplay(1)
    );
}