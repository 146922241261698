import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/core/material/material.module';
import { BehaviorSubject, map, tap, catchError, of, Observable, merge, shareReplay, switchMap } from 'rxjs';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { SecondsToPercentPipe } from './pipes/seconds-to-percent.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientState, ClientStatus } from 'app/models/client/client-state';
import { DateTime } from 'luxon';
import { CLIENT_STATE } from 'app/services/client.state';
import { OBJECTSSESSION, ObjectKindUI, ObjectSession } from 'app/services/objects-session';
import { TimeTrackingService } from 'app/services/time-tracking.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IdleTimeComponent } from '../idle-time/idle-time.component';
import { SignalRService } from 'app/services/signal-r.service';
import { MatRippleModule } from '@angular/material/core';


@Component({
  selector: 'tm-time-tracking-widget',
  standalone: true,
  imports: [CommonModule, MaterialModule, SecondsToTimePipe, SecondsToPercentPipe, FormsModule, ReactiveFormsModule, DragDropModule, RouterModule, MatTooltipModule, MatRippleModule],
  templateUrl: './time-tracking-widget.component.html',
  styleUrls: ['./time-tracking-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimeTrackingWidgetComponent implements OnInit {
  private dialogRef: MatDialogRef<IdleTimeComponent>;
  tooltip$ = new BehaviorSubject<string>('');
  objectsList: Array<ObjectSession> = [];
  private readonly _error$ = new BehaviorSubject<string | undefined>(undefined);

  readonly durationActive$ = this.timeTrackingService.durationActive$;

  get duration$() {
    return this.timeTrackingService.duration$;
  }

  readonly errors$ = merge(this.timeTrackingService.error$, this._error$).pipe(
    shareReplay(1)
  );

  get isPlayValue() {
    return this.timeTrackingService.isPlayValue;
  }

  get isPlay$() {
    return this.timeTrackingService.isPlay$;
  }

  readonly isConnectedSignalR$ = this.signalRService.isConnected$;

  readonly state$ = this.objectsSessions$.pipe(
    tap(objectsSessions => this.objectsList = objectsSessions),
    switchMap(() => this.clientState$),
    map(state => state.client),
    map(({ session, status, timeWorked, committedAt, pauseToken }) => {
      this._error$.next(null);
      this.timeTrackingService.init(timeWorked, committedAt);
      if (session) {
        if (status === ClientStatus.Started) {
          this.timeTrackingService.run(session.id, committedAt, session.startedAt);
        }
        if (status === ClientStatus.Paused) {
          this.timeTrackingService.sessionId = session.id;
          this.dialogRef?.close();
          this.dialogRef = this.dialog.open(IdleTimeComponent, { data: { session, pauseToken, committedAt, objectsList: this.objectsList }, disableClose: true }).addPanelClass(['min-w-72',]);
        }
      }
      return ({ objectId: session?.objectId, status });
    }),
  ).pipe(
    catchError((err) => {
      const date = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
      this.timeTrackingService.init(0, date);
      this._error$.next('Error get state!');
      return of(true);
    })
  );

  constructor(
    @Inject(CLIENT_STATE) private readonly clientState$: Observable<ClientState>,
    @Inject(OBJECTSSESSION) readonly objectsSessions$: Observable<Array<ObjectSession>>,
    private readonly timeTrackingService: TimeTrackingService,
    private readonly dialog: MatDialog,
    private readonly signalRService: SignalRService,
  ) { }

  private setTooltip(objectId: string) {
    const selectedObject = this.objectsList.find(object => object.id === objectId);
    const tooltipMsg = `Active ${ObjectKindUI[selectedObject?.objectKind]}: ${selectedObject?.title}`;
    this.tooltip$.next(tooltipMsg);
  }

  private getTitle(state: any, parent: any): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngOnInit(): void {
    this.timeTrackingService.clientStarted$.pipe(
    ).subscribe({
      next: ({ objectId, sessionId, startedAt, objectKind }) => {
        this.dialogRef?.close();
        this.setTooltip(objectId);
        if (!this.isPlayValue) {
          this.timeTrackingService.run(sessionId, startedAt);
        }
      }
    });

    this.timeTrackingService.clientTimeWorkedUpdated$.pipe(
    ).subscribe({
      next: ({ timeWorked, commitedAtt }) => this.timeTrackingService.updateDuration(timeWorked, commitedAtt)
    });

    this.timeTrackingService.clientStopped$.pipe(
    ).subscribe({
      next: ({ sessionId, timeWorked, stoppedAt }) => {
        this.dialogRef?.close();
        this.tooltip$.next('');
        this.timeTrackingService.init(timeWorked, stoppedAt);
      },
      error: (err) => {
        this._error$.next(err);
      }
    });

    this.timeTrackingService.clientSessionObjectChanged$.pipe(
    ).subscribe({
      next: ({ objectId }) => {
        this.setTooltip(objectId);
      },
      error: (err) => {
        this._error$.next(err);
      }
    });
  }
}