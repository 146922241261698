import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, retry, tap } from 'rxjs';
import { OrganizationService } from 'app/services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly organizationService: OrganizationService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    const newReq = req.clone({
      setHeaders: {
        'X-Organization-Id': this.organizationService.organizationSelectedId,
      },
    });
    // Response
    return next.handle(newReq).pipe(
      retry({ count: 1, delay: 3000 }),
      tap({
        error: (error) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            console.error('---400---');
          }
          if (error instanceof HttpErrorResponse && (error.status === 0 || error.status === 404)) {
            console.error('---0---404----');
            //location.replace('404-not-found');
          }
          if (error instanceof HttpErrorResponse && error.status === 504) {
            console.error('---504---');
            //this.router.navigateByUrl('/404-not-found');
          }
          if (error instanceof HttpErrorResponse && error.status === 500) {
            // this.router.navigateByUrl('errors/error');
          }
          if (error instanceof HttpErrorResponse && error.status === 401) {
            console.error('---401---');
            // const activeAccount =
            //   this.authService.instance.getActiveAccount() ||
            //   this.authService.instance.getAllAccounts()[0];
            // this.authService.logoutRedirect({
            //   account: activeAccount,
            // });
          }
        }
      }),
    );
  }
}
