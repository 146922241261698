import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, retry, startWith, Subject, switchMap, tap } from 'rxjs';
import { OrganizationService } from './organization.service';
import { UserService } from './user.service';
import { NavigationService } from './navigation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationView } from 'app/models/organization/organization';
import { User } from 'app/models/user/user';

function initializeAppFactory(organizationService: OrganizationService, userService: UserService) {
}


@Injectable({
  providedIn: 'root'
})
export class OrganizationGuardService {
  readonly refresh$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly _organizationService: OrganizationService,
    private readonly _userService: UserService,
    private readonly _navigationService: NavigationService,
  ) { }

  readonly guard$ = this.refresh$.pipe(
    startWith(''),
    switchMap(() => this._organizationService.getOrganizations()),
    tap({
      next: (organizations) => {
        if (organizations?.length > 0) {
          this._organizationService.organizations = organizations;
          return;
        };
        this.router.navigate(['/company/complete-registration']);
      },
      error: (error) => {
        console.log(error)
        if (error instanceof HttpErrorResponse && (error.status === 500)) {
          this.router.navigate(['/errors/maintenance']);
        }
        return false;
      },
    }),
    switchMap(() => this._organizationService.organizationInit()),
    // tap({
    //   next: (organization) => {
    //     if (!organization?.onboarding?.completed && organization?.isOwner) {
    //       this.router.navigate(['organization/onboarding']);
    //     }
    //   },
    // }),
    retry({ count: 1, delay: 1000 }),
    tap({
      error: (err) => {
        console.error(err);
        this.router.navigateByUrl('/errors/something-went-wrong');
      }
    }),
    map((organization: OrganizationView) => {
      const user = organization.users?.length ? organization.users.at(0) : null;
      if (user) {
        return { user, organization };
      } else this.router.navigate(['access-denied'])
    }),
    switchMap(({ user, organization }) => this._organizationService.viewUser(user.id).pipe(
      tap((_user) => {
        const user = { ..._user, displayName: _user.displayName ?? `User-${_user.id.split('-')[0]}` };
        console.log(user);
        this._userService.setUser(user);
        this._userService.user = user;
        if (!user?.onboarding?.completed && !organization?.isOwner) {
          // this.router.navigate(['/user/complete-registration']);
        }
      })
    )),
    switchMap((user: User) => this._navigationService.get(user.role)),
    map(() => true),
  ) as Observable<boolean>
}
