import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { BehaviorSubject, EMPTY, Observable, Subject, debounceTime, distinctUntilChanged, filter, map, merge, startWith, switchMap, takeUntil } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NewSession } from 'app/models/sessions/session';
import { ClientService } from 'app/services/client.service';
import { OBJECTSSESSION, ObjectKind, ObjectSession } from 'app/services/objects-session';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/core/material/material.module';
import { NumberToTimePipe } from '../../../edit-work-hours-timeline/pipes/seconds-to-time.pipe';
import { MatTabGroup } from '@angular/material/tabs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTimepickerModule, provideNativeDateTimeAdapter, MAT_TIMEPICKER_DEFAULT_OPTIONS } from '@dhutaryan/ngx-mat-timepicker';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DurationFormFieldComponent, Duration } from '../duration-form-field/duration-form-field.component';

export function rangeValidator(): ValidatorFn {
  return (form: AbstractControl<any>): ValidationErrors | null => {
    const start = form.get('start').value as Date;
    const end = form.get('end').value as Date;
    const startDT = DateTime.fromJSDate(start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDT = DateTime.fromJSDate(end).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (startDT.toMillis() !== endDT.toMillis()) {
      form.get('end').setErrors({ nextDay: true });
    }
    if (+new Date(start) >= (+new Date(end))) {
      form.get('end').setErrors({ noValid: true });
    }
    // if (+new Date(end) <= +new Date(start)) {
    //   form.get('start').setErrors({ noValid: true });
    // }
    return null;
  }
}

function transformSeconds(seconds: number): Duration {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(((seconds / 60) % 60));
  const s = Math.floor((seconds % 60));
  if (seconds > 0) {
    const hh = `${(h <= 9) ? '0' + h : h}`;
    const mm = `${(m <= 9) ? '0' + m : m}`;
    const ss = `${(s <= 9) ? '0' + s : s}`;
    return new Duration(hh, mm, ss);
  } else {
    return new Duration('00', '00', '00');
  }
}

export interface Data {
  session: NewSession,
  type: 'add' | 'edit'
}

@Component({
  selector: 'tm-session-add',
  standalone: true,
  imports: [
    CommonModule, MaterialModule,
    ReactiveFormsModule, FormsModule,
    NgxMatSelectSearchModule, MatTimepickerModule,
    DurationFormFieldComponent
  ],
  templateUrl: './session-add.component.html',
  styleUrls: ['./session-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [//provideNativeDateTimeAdapter(),
    {
      provide: MAT_TIMEPICKER_DEFAULT_OPTIONS, useValue: { mode: 'input', format: '24h' }
    },
  ],
})
export class SessionAddComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup, { static: true }) matTabGroup: MatTabGroup;
  private readonly destroy$ = new Subject<void>();
  readonly projectFilterCtrl = new FormControl<string>('');
  readonly ticketFilterCtrl = new FormControl<string>('');
  readonly maxTime = new Date();
  readonly submitting$ = new BehaviorSubject<boolean>(false);
  private readonly currentDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  readonly sessionForm: FormGroup = this.fb.group({
    object: [null, Validators.required],
    date: [this.currentDate, [Validators.required]],
    start: [this.currentDate.toISODate(), [Validators.required]],
    end: [this.currentDate.toISODate(), [Validators.required]],
    duration: new Duration('', '', ''),
  }, { validators: [rangeValidator()] });

  private readonly projects$ = this.objectsSession$.pipe(
    map(objects => objects.filter(object => object.objectKind === ObjectKind.Project))
  );

  private readonly tickets$ = this.objectsSession$.pipe(
    map(objects => objects.filter(object => object.objectKind === ObjectKind.Ticket))
  );

  readonly projectsListFilter$ = this.projectFilterCtrl.valueChanges.pipe(
    startWith(''),
    debounceTime(150),
    switchMap((searchText) => {
      if (searchText) {
        return this.projects$.pipe(
          map((projects) => projects.filter(project => project.title?.toLowerCase().indexOf((searchText || '').toLowerCase()) > -1))
        )
      } else return this.projects$;
    })
  );

  readonly ticketsListFilter$ = this.ticketFilterCtrl.valueChanges.pipe(
    startWith(''),
    debounceTime(150),
    switchMap((searchText) => {
      if (searchText) {
        return this.tickets$.pipe(
          map((tickets) => tickets.filter(ticket => ticket.title?.toLowerCase().indexOf((searchText || '').toLowerCase()) > -1))
        )
      } else return this.tickets$;
    })
  );

  get sessionStart(): Date {
    return this.sessionForm.get('start').value;
  }

  get sessionEnd(): Date {
    return this.sessionForm.get('end').value;
  }

  get objectId() {
    return this.sessionForm.get('object').value.id;
  }

  get objectKind() {
    return this.sessionForm.get('object').value.objectKind;
  }

  get duration() {
    return DateTime.fromJSDate(this.sessionEnd).diff(DateTime.fromJSDate(this.sessionStart), 'seconds').seconds;
  }

  readonly durationFC = this.sessionForm.get('duration');

  get sessionFormInvalid() {
    return this.sessionForm.invalid;
  }

  readonly maxStartTime = (value: Date) => {
    const endDate = new Date(this.sessionEnd);
    return +value < +endDate;

  }
  readonly minEndTime = (value: Date) => {
    const startDate = new Date(this.sessionStart);
    return +value > +startDate;
  }

  // closedPickerStart() {
  //   this.sessionForm.get('end').updateValueAndValidity({ emitEvent: false });
  // }

  // closedPickerEnd() {
  //   this.sessionForm.get('start').updateValueAndValidity({ emitEvent: false });
  // }

  readonly objects$ = this.objectsSession$.pipe(
    map(objects => objects.reduce((acc, curr) => {
      if (acc[curr.category]) {
        acc[curr.category].push(curr);
      } else acc[curr.category] = [curr];
      return acc;
    }, {})),
    map((objects: { [key: string]: Array<ObjectSession> }) => {
      let data = {};
      for (const [key, value] of Object.entries(objects)) {
        data[`${key}`] = value.sort((v1, v2) => v1.title.localeCompare(v2.title))
      }
      return data;
    })
  );

  constructor(
    @Inject(OBJECTSSESSION) private readonly objectsSession$: Observable<Array<ObjectSession>>,
    private readonly dialogRef: MatDialogRef<SessionAddComponent>,
    private readonly fb: FormBuilder,
    private readonly clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) public readonly data: Data) {
    this.sessionForm.patchValue({
      object: (data.session.objectId && data.session.objectKind) ? { id: data.session.objectId, objectKind: data.session.objectKind } : null,
      date: DateTime.fromJSDate(data.session.startTime as Date),
      start: new Date(data.session.startTime),
      end: new Date(data.session.endTime),
    });
  }

  compareFn(o1: ObjectSession, o2: ObjectSession) {
    return o1?.id === o2?.id;
  }

  private _submit(session: NewSession) {
    switch (this.data.type) {
      case 'add':
        return this.clientService.addSession(session);
      case 'edit':
        return this.clientService.changeSession(this.data.session.id, session);
      default:
        return EMPTY;
    }
  }

  dateChange({ value }: MatDatepickerInputEvent<DateTime>) {
    const start = this.sessionStart;
    const end = this.sessionEnd;
    const newStart = DateTime.fromMillis(value.toMillis()).set({ hour: start.getHours(), minute: start.getMinutes(), second: 0 });
    const newEnd = DateTime.fromMillis(value.toMillis()).set({ hour: end.getHours(), minute: end.getMinutes(), second: 0 });
    this.sessionForm.get('start').setValue(newStart.toJSDate());
    this.sessionForm.get('end').setValue(newEnd.toJSDate())
  }

  submit(): void {
    this.submitting$.next(true);
    const session = {
      startTime: this.sessionStart.toJSON(),
      endTime: this.sessionEnd.toJSON(),
      objectId: this.objectId,
      objectKind: this.objectKind
    };
    this._submit(session).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: () => {
        this.submitting$.next(false);
      }
    });
  }

  ngOnInit(): void {
    if (this.data.session.objectKind === ObjectKind.Ticket) {
      this.matTabGroup.selectedIndex = 1;
    }

    this.sessionForm.get('object').valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(300),
    ).subscribe(value => {
      this.sessionForm.get('object').reset(value, { emitEvent: false });
    });

    merge(this.sessionForm.get('start').valueChanges, this.sessionForm.get('end').valueChanges).pipe(
      startWith(''),
      debounceTime(200),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      const duration = transformSeconds(this.duration);
      this.durationFC.setValue(duration, { emitEvent: false });
    });

    this.sessionForm.get('end').valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(200),
    ).subscribe((end: Date) => {
      const start = this.sessionStart;
      const newEnd = DateTime.fromMillis(start.getTime()).set({ hour: end.getHours(), minute: end.getMinutes(), second: end.getSeconds() }).toJSDate();
      this.sessionForm.get('end').setValue(newEnd, { emitEvent: false });
    });

    this.sessionForm.get('duration').valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(200),
      filter(duration => duration !== null),
      distinctUntilChanged((prevDuration: Duration, duration: Duration) => prevDuration.hour !== duration.hour && prevDuration.minute !== duration.minute && prevDuration.second !== duration.second),
    ).subscribe((duration: Duration) => {
      const start = this.sessionStart;
      const { hour, minute, second } = duration;
      if (!isNaN(+hour) || !isNaN(+minute) || !isNaN(+second)) {
        const newEnd = DateTime.fromJSDate(start).plus({ hour: +hour, minute: +minute, second: +second }).toJSDate();
        this.sessionForm.get('end').setValue(newEnd);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}