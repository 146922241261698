<div role="group" class="duration-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="duration-input-element w-8"
         formControlName="hour" size="2"
         maxLength="2"
         aria-label="Hours number"
         (input)="_handleInput(parts.controls.hour, minute)"
         #hour>
  <span class="duration-input-spacer">:</span>
  <input class="duration-input-element w-8"
         formControlName="minute"
         maxLength="2"
         size="2"
         aria-label="Minites number"
         (input)="_handleInput(parts.controls.minute, second)"
         (keyup.backspace)="autoFocusPrev(parts.controls.minute, hour)"
         #minute>
  <span class="duration-input-spacer">:</span>
  <input class="duration-input-element w-8"
         formControlName="second"
         maxLength="2"
         size="2"
         aria-label="Seconds number"
         (input)="_handleInput(parts.controls.second)"
         (keyup.backspace)="autoFocusPrev(parts.controls.second, minute)"
         #second>
</div>