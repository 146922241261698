import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule, ROUTES, TitleStrategy } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AppConfigModule } from './app-config.module';
import { LayoutModule } from './layout/layout.module';
import { CommonComponentsModule } from './shared/common-components.module';
import 'hammerjs';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { AppServiceModule } from './modules/app-service/app-service.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { FuseLoadingInterceptor } from '@fuse/services/loading/loading.interceptor';
import { SignUpComponent } from './shared/pages/sign-up/sign-up.component';
import { RegistrationCompleteComponent } from './modules/organization/modules/settings/pages/registration-complete/registration-complete.component';
import { SignOutComponent } from './shared/pages/sign-out/sign-out.component';
import { IsNewVersionMsgAppComponent } from './shared/components/is-new-version-msg-app/is-new-version-msg-app.component';
import { Auth0Module } from './auth0/auth0.module';
import { TimeTitleStrategyService } from './services/time-title-strategy.service';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { SignalRService } from './services/signal-r.service';
import { SignalRSWService } from './services/signal-r-sw.service';
import { TimeTrackingService } from './services/time-tracking.service';
import { TimeTrackingSWService } from './services/time-tracking-sw.service';


@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: false },
    'rotate': { enable: false }
  }
}

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  // enableTracing: true
  // scrollPositionRestoration: 'enabled',
  // useHash: false,
  // anchorScrolling: 'enabled', 
  // onSameUrlNavigation: 'reload'
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    CoreModule,
    LayoutModule,
    AppConfigModule,
    Auth0Module,
    HttpClientModule,
    CommonComponentsModule,
    HammerModule,
    AppServiceModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RegistrationCompleteComponent,
    SignUpComponent,
    SignOutComponent,
    IsNewVersionMsgAppComponent
  ],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TimeTitleStrategyService,
    },
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FuseLoadingInterceptor,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { autoFocus: false }
    },
    {
      provide: SignalRService,
      useFactory: () => (typeof SharedWorker !== 'undefined') ? new SignalRSWService() : new SignalRService()
    },
    {
      provide: TimeTrackingService,
      useFactory: () => (typeof SharedWorker !== 'undefined') ? new TimeTrackingSWService() : new TimeTrackingService()
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInit,
    //   deps: [OrganizationService, UserService, NavigationService, Router],
    //   multi: true
    // }
  ],
  exports: [RouterModule],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }



// function appInit(organizationService: OrganizationService, userService: UserService, navigationService: NavigationService, router: Router) {
//   return async () => await lastValueFrom(organizationService.getOrganizations().pipe(
//     tap({
//       next: (organizations) => {
//         if (organizations?.length > 0) {
//           organizationService.organizations = organizations;
//           return;
//         };
//         this.router.navigate(['/company/complete-registration']);
//       },
//       error: (error) => {
//         console.log(error)
//         if (error instanceof HttpErrorResponse && (error.status === 500)) {
//           router.navigate(['/errors/maintenance']);
//         }
//         return false;
//       },
//     }),
//     switchMap(() => organizationService.organizationInit()),
//     retry({ count: 1, delay: 1000 }),
//     tap({
//       error: (err) => {
//         console.error(err);
//         router.navigateByUrl('/errors/something-went-wrong');
//       }
//     }),
//     map((organization: OrganizationView) => {
//       const user = organization.users?.length ? organization.users.at(0) : null;
//       if (user) {
//         return { user, organization };
//       } else router.navigate(['access-denied'])
//     }),
//     switchMap(({ user, organization }) => organizationService.viewUser(user.id).pipe(
//       tap((_user) => {
//         const user = { ..._user, displayName: _user.displayName ?? `User-${_user.id.split('-')[0]}` };
//         console.log(user);
//         userService.setUser(user);
//         userService.user = user;
//         if (!user?.onboarding?.completed && !organization?.isOwner) {
//           // this.router.navigate(['/user/complete-registration']);
//         }
//       })
//     )),
//     switchMap((user: User) => navigationService.get(user.role)),
//   ));
// }