// import { inject, Injectable, NgZone } from '@angular/core';
// import { combineLatest, filter, lastValueFrom, map, ReplaySubject, shareReplay, skip, startWith, Subject, switchMap, take, tap } from 'rxjs';
// import { OrganizationService } from './organization.service';
// import { AuthService } from '@auth0/auth0-angular';
// import { NetworkService } from './network.service';

// @Injectable({
//   providedIn: 'root'
// })
// export class SignalRSWService {
//   private sharedWorker!: SharedWorker;
//   private readonly _reconnected$ = new Subject<void>();
//   private readonly _isConnect$ = new ReplaySubject<boolean>(1);
//   private readonly _error$ = new Subject<void>();
//   private readonly auth0Service = inject(AuthService);
//   private readonly organizationService = inject(OrganizationService);
//   private readonly zone = inject(NgZone);
//   private readonly networkService = inject(NetworkService);
//   private readonly invokeEvents$ = new Subject<any>();
//   private connect$ = new Subject<void>();

//   private readonly onEvents$ = new Subject<{ methodName: string, args: Array<any> }>();

//   readonly isConnected$ = combineLatest({ isConnect: this._isConnect$, isOnline: this.networkService.isOnline$ }).pipe(
//     map(({ isConnect, isOnline }) => (isConnect === true && isOnline === true) ? true : false),
//     shareReplay({ bufferSize: 1, refCount: true })
//   );

//   readonly isReconnected$ = this._reconnected$.asObservable().pipe(
//     skip(1)
//   );

//   readonly onlineChanged$ = this.on('UserOnlineChanged').pipe(
//     map(args => {
//       const [id, online] = args;
//       return { id, online };
//     })
//   );

//   constructor() {
//     this.initializeSharedWorker().subscribe(() => this.connect());
//   }

//   private initializeSharedWorker() {
//     if (typeof SharedWorker === 'undefined') {
//       console.error('SharedWorker is not supported in this environment');
//       return;
//     }
//     this.sharedWorker = new SharedWorker(new URL('/src/app/shared.worker', import.meta.url), { name: 'TimeShared', type: 'module' }); // this is the path of our shared-worker.js file.
//     this.sharedWorker.port.start();
//     this.sharedWorker.port.onmessage = (event) => {
//       const { command, error, methodName, args, data, eventId } = event.data;
//       this.zone.run(() => {
//         switch (command) {
//           case 'getToken':
//             console.log('getToken')
//             lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1)))
//               .then((token) => this.sharedWorker.port.postMessage({ command: 'setToken', data: { token } }));
//             break;
//           case 'connected':
//             this._isConnect$.next(true);
//             break;
//           case 'connectionError':
//             this.connectionErrorHandler(error);
//             break;
//           case 'invoke':
//             this.invokeEvents$.next({ eventId, data });
//             break;
//           case 'on':
//             this.onEvents$.next({ methodName, args });
//             break;
//           case 'reconnected':
//             this._reconnected$.next();
//             break;
//           case 'invokeError':
//             this.invokeEvents$.next({ eventId, error });
//             break;
//         }
//       });
//     };
//     return this.connect$.pipe(
//       startWith(''),
//       switchMap(() => this.auth0Service.isAuthenticated$),
//       filter(isAuthenticated => !!isAuthenticated)
//     )
//   }

//   async connect() {
//     const data = {
//       orgId: this.organizationService.organizationSelectedId,
//       token: await lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1)))
//     };
//     this.sharedWorker.port.postMessage({ command: 'connect', data });
//   }

//   on(methodName: string) {
//     const data = {
//       methodName,
//     };
//     return this.isConnected$.pipe(
//       filter(isConnect => isConnect),
//       tap(() => this.sharedWorker.port.postMessage({ command: 'on', data })),
//       switchMap(() => this.onEvents$.asObservable()),
//       filter((event) => event.methodName === methodName),
//       map((event) => event.args),
//       shareReplay({ bufferSize: 1, refCount: true })
//     );
//   }

//   invoke(methodName: string, args: any[]) {
//     return new Promise<any>((resolve, reject) => {
//       const eventId = +new Date();
//       const data = {
//         eventId,
//         methodName,
//         args
//       };
//       this.sharedWorker.port.postMessage({ command: 'invoke', data });
//       this.invokeEvents$.asObservable().pipe(
//         filter(data => data.eventId === eventId),
//         take(1)
//       ).subscribe((invokeData) => {
//         const { error, data } = invokeData;
//         if (error) {
//           reject(error);
//         } else {
//           resolve(data);
//         }
//       });
//     });
//   }

//   invokeV2(methodName: string, args: any) {
//     return new Promise<any>((resolve, reject) => {
//       const eventId = +new Date();
//       const data = {
//         eventId,
//         methodName,
//         args
//       };
//       this.sharedWorker.port.postMessage({ command: 'invokeV2', data });
//       this.invokeEvents$.asObservable().pipe(
//         filter(data => data.eventId === eventId),
//         take(1)
//       ).subscribe((invokeData) => {
//         const { error, data } = invokeData;
//         if (error) {
//           reject(error);
//         } else {
//           resolve(data);
//         }
//       });
//     });
//   }

//   private connectionErrorHandler(err?: any) {
//     console.error(err);
//     this._error$.next();
//     this._isConnect$.next(false);
//   }
// }


import { inject, Injectable } from '@angular/core';
import { filter, lastValueFrom, map, shareReplay, Subject, switchMap, take, tap } from 'rxjs';
import { SignalRBase } from 'app/classes/signalR-base';
import { NAVIGATOR } from 'app/core/core.module';
import { version } from "environments/sswv";

@Injectable({
  providedIn: 'root'
})
export class SignalRSWService extends SignalRBase {
  private sharedWorker!: SharedWorker;
  private readonly invokeEvents$ = new Subject<any>();
  private readonly onEvents$ = new Subject<{ methodName: string, args: Array<any> }>();
  private readonly navigator = inject(NAVIGATOR);

  constructor() {
    super();
    this.initializeSharedWorker().subscribe(() => this.connect());
  }

  readonly onlineChanged$ = this.on('UserOnlineChanged').pipe(
    map(args => {
      const [id, online] = args;
      return { id, online };
    })
  );

  private initializeSharedWorker() {
    if (typeof SharedWorker === 'undefined') {
      console.error('SharedWorker is not supported in this environment');
      return;
    }

    this.sharedWorker = new SharedWorker(new URL('/src/app/shared.worker', import.meta.url), { name: `SignalRSharedWorker_${version}`, type: 'module' }); // this is the path of our shared-worker.js file.
    this.sharedWorker.port.start();
    this.sharedWorker.port.onmessage = (event) => {
      const { command, error, methodName, args, data, eventId } = event.data;
      this.zone.run(() => {
        switch (command) {
          case 'getToken':
            lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1)))
              .then((token) => this.sharedWorker.port.postMessage({ command: 'setToken', data: { token } }));
            break;
          case 'connected':
            this._isConnect$.next(true);
            break;
          case 'connectionError':
            this.connectionErrorHandler(error);
            break;
          case 'invoke':
            this.invokeEvents$.next({ eventId, data });
            break;
          case 'invokeV2':
            this.invokeEvents$.next({ eventId, data });
            break;
          case 'on':
            this.onEvents$.next({ methodName, args });
            break;
          case 'onV2':
            this.onEvents$.next({ methodName, args });
            break;
          case 'reconnected':
            this._reconnected$.next();
            break;
          case 'invokeError':
            this.invokeEvents$.next({ eventId, error });
            break;
        }
      });
    };
    // for removed tabs
    if (typeof this.navigator?.locks !== 'undefined') {
      let lockName = crypto.randomUUID();
      let never = () => new Promise(() => { });
      this.navigator.locks.request(lockName, never);
      this.sharedWorker.port.postMessage({ command: 'lock', data: { lockName } });
    }
    return this.auth0Service.isAuthenticated$.pipe(
      filter(isAuthenticated => !!isAuthenticated)
    )
  }

  async connect() {
    const data = {
      orgId: this.organizationService.organizationSelectedId,
      token: await lastValueFrom(this.auth0Service.getAccessTokenSilently().pipe(take(1))) //lastValueFrom(this.auth0Service.idTokenClaims$.pipe(map(token => token.__raw), take(1)))
    };
    this.sharedWorker.port.postMessage({ command: 'connect', data });
  }

  on(methodName: string) {
    const data = {
      methodName,
    };
    return this.isConnected$.pipe(
      filter(isConnect => isConnect),
      tap(() => this.sharedWorker.port.postMessage({ command: 'on', data })),
      switchMap(() => this.onEvents$.asObservable()),
      filter((event) => event.methodName === methodName),
      map((event) => event.args),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  onV2(methodName: string) {
    const data = {
      methodName,
    };
    return this.isConnected$.pipe(
      filter(isConnect => isConnect),
      tap(() => this.sharedWorker.port.postMessage({ command: 'onV2', data })),
      switchMap(() => this.onEvents$.asObservable()),
      filter((event) => event.methodName === methodName),
      map((event) => event.args),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  invoke(methodName: string, args: any[]) {
    return new Promise<any>((resolve, reject) => {
      const eventId = Date.now();
      const data = {
        eventId,
        methodName,
        args
      };
      this.sharedWorker.port.postMessage({ command: 'invoke', data });
      this.invokeEvents$.asObservable().pipe(
        filter(data => data.eventId === eventId),
        take(1)
      ).subscribe((invokeData) => {
        const { error, data } = invokeData;
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  invokeV2(methodName: string, args: any) {
    return new Promise<any>((resolve, reject) => {
      const eventId = Date.now();
      const data = {
        eventId,
        methodName,
        args
      };
      this.sharedWorker.port.postMessage({ command: 'invokeV2', data });
      this.invokeEvents$.asObservable().pipe(
        filter(data => data.eventId === eventId),
        take(1)
      ).subscribe((invokeData) => {
        const { error, data } = invokeData;
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }
}